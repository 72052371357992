import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
const Vue3Mq = require('vue3-mq');
// import { VueReCaptcha } from 'vue-recaptcha-v3'

const app = createApp(App);
app.use(store);
app.use(router);
app.component('MqResponsive', Vue3Mq.MqResponsive)
// app.use(VueReCaptcha, { siteKey: '6Lc6NLsnAAAAAKw0oPIgP_ZgDUWdDdYmyhQKw-bN' })
app.mount('#app');

Vue3Mq.updateBreakpoints({
    preset: "bootstrap5"
  })