<template>
    <MqResponsive :target="['xs','sm','md']" class="Shoplist"> 
        <h2 class="detail"><button @click="this.$router.push({name: 'eventdetail'})">Detail</button></h2>
        <!-- TODO detail -->
        <h2 class="title">Qui ramène quoi ?</h2>
        <span v-if="list">
            <div v-for="(object, index) in this.list" :key="index" class="ojbList">
                <h2 @click="this.displayQte(object);" class="objname">{{ object.name }}</h2>
                <h2 class="objqte">{{ object.quantity }}</h2>
                <button @click="DeleteItem(object.id_Item)" class="objrm"  v-if="this.$store.state.event.isPassed === false"><img src="../assets/icons/remove.png" /></button>
            </div>
            <div v-if="this.DetailObj" class="DetailObj">
                <div v-for="(det, index) in this.objDetail" :key="index">
                    <h2 v-if="det.surname != this.$store.state.user.surname">{{ det.surname }}: {{ det.quantity }}</h2>
                </div>
                <h2>J'en ammene: {{ this.qte }}</h2>
                <span class="ObjAddRm">
                    <h2 @click="this.qte ++">+</h2>
                    <h2 v-if="this.qte > 0" @click="this.qte = this.qte - 1">-</h2>
                </span>
                <button @click="this.DetailObj = false; this.UpdateObj(this.objid, this.qte); this.objid = null">quit</button>
            </div>
        </span>
        <!-- Add, if name is unknown create item and add to event else add to event  -->
        <!-- Get item list and plus button to add -->
        <button v-if="!this.displayList && this.$store.state.event.isPassed === false" @click="this.displayList = true; this.getCategories();"><img class="openListbtn" src="../assets/icons/remove.png"/></button>
        <span v-if="this.displayList">
            <h2 class="catalogueTitle">Catalogue:</h2>
            <div v-if="this.globalItemList === null" v-for="(category, index) in this.globalCategoriesList" :key="index">
                <!-- <div v-if="category.id_Parent === null"> -->
                    <h2 class="objname"><button @click="this.GetItemsFromCategory(category)">{{category.name}}</button></h2>
                <!-- </div> -->
            </div>
            <!-- Display all items -->
            <div v-if="this.globalItemList" v-for="(item, index) in this.globalItemList" :key="index">
                <div v-if="this.list.find(o => o.name === item.name) === undefined" class="ojbList">
                    <h2 class="objname">{{item.name}}</h2>
                    <button @click="this.AddItem(item.id)" class="objadd"><img src="../assets/icons/remove.png" /></button>
                </div>
            </div>
            <h2 class="objname"><button @click="this.getCategories(); this.globalItemList = null" v-if="this.globalItemList != null">Retour!</button></h2>
            <button @click="this.displayList = false; this.globalItemList = null;"><img class="closeListbtn" src="../assets/icons/remove.png" /></button>
        </span>
    </MqResponsive>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            list: null,
            name: null,
            desc: null,
            displayList: false,
            globalCategoriesList: [],
            globalSubCategoriesList: [],
            globalItemList: null,
            timer: null,
            objDetail:null,
            DetailObj: false,
            qte: null,
            objid: null,
        }
    },
    created() {
        this.getShoppingList()
    },
    mounted: function () {
    this.timer = setInterval(() => {
        this.getShoppingList()
    }, 5000)
    },
    beforeUnmount(){
        clearInterval(this.timer)
    },
    methods:{
        async getShoppingList(){
            console.log("get shopping list")
            try {
                const uri = this.$store.state.apiURI +'event/shoppinglist/get';
                const response = await axios.post(uri,{
                    eventid: this.$store.state.event.id 
                },{
                headers: {
                    'Authorization': 'Bearer ' + this.$store.state.token
                }
            });
            this.list = response.data[0]
            } catch (error) {
                console.error(error);
            }
        },
        async getCategories(){
            try {
                const uri = this.$store.state.apiURI +'items/getcategories';
                const response = await axios.post(uri,{},{
                headers: {
                    'Authorization': 'Bearer ' + this.$store.state.token
                }
            });
            var resp = response.data[0]
            this.globalCategoriesList = [],
            this.globalSubCategoriesList = [],
            resp.forEach(category => {
                if(category.id_Parent != null )
                {
                    this.globalSubCategoriesList.push(category);
                }else{
                    this.globalCategoriesList.push(category);
                }
            });
            console.log(this.globalCategoriesList)
            } catch (error) {
                console.error(error);
            }
        },
        async GetItemsFromCategory(category){
            try {

                switch (category.id) {
                    case 32:
                        //display sub categories
                        console.log(category)
                        this.globalCategoriesList = this.globalSubCategoriesList;
                        break;

                    default:
                        const uri = this.$store.state.apiURI +'items/getallfromcategory';
                        const response = await axios.post(uri,{
                            categoryid: category.id,
                        },{
                        headers: {
                            'Authorization': 'Bearer ' + this.$store.state.token
                        }
                        });
                        this.globalItemList = response.data[0]
                        break;
                }
            } catch (error) {
                console.error(error);
            }
        },
        async GetItems(){
            try {
                const uri = this.$store.state.apiURI +'items/getall';
                const response = await axios.post(uri,{},{
                headers: {
                    'Authorization': 'Bearer ' + this.$store.state.token
                }
            });
            this.globalItemList = response.data[0]
            } catch (error) {
                console.error(error);
            }
        },
        async displayQte(object){
            try {
                const uri = this.$store.state.apiURI +'items/getitembringer';
                const response = await axios.post(uri,{
                    itemid: object.id_Item,
                    eventid: this.$store.state.event.id
                },{
                headers: {
                    'Authorization': 'Bearer ' + this.$store.state.token
                }
            });
            this.objid = object.id_Item;
            this.objDetail = response.data[0];
            this.DetailObj = true;
            this.objDetail.forEach(e => {
                if(e.surname === this.$store.state.user.surname){
                    this.qte = e.quantity;
                }
            });
            console.log(this.objDetail)
            } catch (error) {
                console.error(error);
            }
        },
        async UpdateObj(objid, qte){
            try {
                const uri = this.$store.state.apiURI +'event/shoppinglist/edit';
                const response = await axios.post(uri,{
                    eventid: this.$store.state.event.id,
                    itemid: objid,
                    quantity: qte
                },{
                headers: {
                    'Authorization': 'Bearer ' + this.$store.state.token
                }
            });
            var res = response.data[0]
            } catch (error) {
                console.error(error);
            }
        },
        async AddItem(itemid){
            try {
                const uri = this.$store.state.apiURI +'event/shoppinglist/add';
                const response = await axios.post(uri,{
                    eventid: this.$store.state.event.id, 
                    itemid: itemid
                },{
                headers: {
                    'Authorization': 'Bearer ' + this.$store.state.token
                }
            });
            this.list = response.data[0]
            this.getShoppingList()
            } catch (error) {
                console.error(error);
            }
        },
        async DeleteItem(itemid){
            try {
                const uri = this.$store.state.apiURI +'event/shoppinglist/delete';
                const response = await axios.post(uri,{
                    eventid: this.$store.state.event.id, 
                    itemid: itemid
                },{
                headers: {
                    'Authorization': 'Bearer ' + this.$store.state.token
                }
            });
            this.list = response.data[0]
            this.getShoppingList()
            } catch (error) {
                console.error(error);
            }
        },
    }
}
</script>
<style>
.Shoplist .detail{
    position: absolute;
    right: 24px;
    top: 0;
}
.Shoplist .title{
    color: #FF9627;
    text-align: left;
    margin-left: 24px;
}
.catalogueTitle{
    color: #FF9627;
    margin-left: 24px;
    margin-bottom: 0;
    text-align: left;
}
.ojbList{
    display: flex; 
    /* justify-content: space-between; */
    margin-left: 25px;
    margin-right: 35px;

}
.ojbList .objname{
    margin-top: 5px;
    margin-bottom: 5px;
    flex-grow: 2;
    text-align:left ;
    width: 50%;
}
.ojbList .objqte{
    margin-top: 5px;
    margin-bottom: 5px;
    flex-grow: 1;
}
.ojbList .objrm{
    margin-top: 5px;
    margin-bottom: 5px;
    flex-grow: 1;
}
.objrm img{
    width: 25px;
}
.objadd img{
    width: 25px;
    rotate: 45deg;
}
.DetailObj{
    border: 1px solid;
    border-radius: 25px;
    position: absolute;
    top: 5px;
    left: 10px;
    right: 10px;
    padding-bottom: 80px;
    background-color: white;
    z-index: 5;
}
.DetailObj .ObjAddRm{
    display: flex; 
    justify-content: space-evenly;

}
.DetailObj h2{
    margin: 0;
    margin-top: 5px;
}
.openListbtn{
    rotate: 45deg;
    width: 30px;
}
.closeListbtn{
    width: 30px;
}
</style>
