<template>
    <button class="returnBTN" @click="this.$router.push({name: 'settings'})"><img src="../assets/icons/arrow.png"/></button>
    <h2 style="margin-bottom: 80px;">
        Mentions Légales: <br/>
        Votre identité : <br/>
        Vincent Vié<br/>
        <br/>
        Vos Coordonnées:<br/>
        340 rue des Capucines<br/>
        45160 Olivet<br/>
        admin@friendsevent.fr<br/>
        <br/>
        Mentions relatives a la propriété intellectuelle:<br/>
        Voir les <a href="https://friendsevent.fr/#/credits">Credits</a><br/>
        <br/>
        Hébergement du site:<br/>
        Vincent Vié<br/>
        Independant<br/>
        340 rue des capucines 45160 Olivet<br/>
        admin@friendsevent.fr<br/>
    </h2>
</template>