<template>
    <img src="../assets/logos/Logo_Avec.png" class="appLogo"/>
    <img src="../assets/logos/Cloud.gif" class="cloudLogo cloud1"/>
    <img src="../assets/logos/Cloud.gif" class="cloudLogo cloud2"/>
    <img src="../assets/logos/Cloud.gif" class="cloudLogo cloud3"/>
    <img src="../assets/background/Friend-10.png" class="backgroundLogin"/>
    <div v-if="!this.$store.state.token" class="hoverimg">
      <span v-if="!this.resetPassword">
        <h1>Connecte toi !</h1>
        <form @submit.prevent="login">
          <input type="text" v-model="email" placeholder="Email" required /><br/>
          <input type="password" v-model="password" placeholder="Mot de passe" required/><br/>
          <button type="submit" class="login">Let's Go !</button>
        </form>
        <h5 @click="this.resetPassword = true" class="forgotPwd">Mot de passe oublié ?</h5>
      </span>
      <span v-if="this.resetPassword">
        <h1>Reset ton Mot de passe !</h1>
        <form @submit.prevent="reset">
          <input type="text" v-model="email" placeholder="Email" required /><br/>
          <button type="submit" class="login">Let's Go !</button>
        </form>
        <h3 @click="this.resetPassword = false">Retour à la connexion</h3>
      </span>
    </div>
    <div v-if="this.incorrectID == true">
      incorrect identifer or password
    </div>
    <div v-if="this.accountDisabled == true">
      Account Disabled
    </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      password: '',
      token: '',
      incorrectID: false,
      accountDisabled: false,
      resetPassword: false,
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.post(this.$store.state.apiURI +'account/login', {
          email: this.email,
          password: this.password,
        });
        console.log(response.status)
        switch (response.status) {
          case 211:
            this.accountDisabled = true;
            break;
          case 212:
            this.incorrectID = true;
            break;
        
          default:
            if(response.data != ""){
              this.token = response.data.token;
              this.user = response.data.user;
              this.$store.commit('setToken', this.token);
              this.$store.commit('setUser', this.user);
              this.$router.push({name: 'home'});
            }
            else{
              this.incorrectID = true;
            }
            break;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async reset(){
      try {
        const response = await axios.post(this.$store.state.apiURI +'account/resetreq', {
          email: this.email,
        });
        console.log(response)
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style>
.appLogo{
  width: 100%; 
  margin-top: 20%;
}
.cloudLogo{
  position:fixed; 
}
.cloud1{
  width: 40%;
  left: 70%;
  top: 3%;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.cloud2{
  width: 30%;
  left: -5%;
  top: 35%;
}
.cloud3{
  width: 30%;
  left: 80%;
  top: 45%;
}
.backgroundLogin{
  margin-left: -25px; 
  margin-right: -55px; 
  width: 112%; 
  position:fixed; 
  bottom: 0; 
  left: 0;
}
.hoverimg{
  position: fixed;
  bottom: 5%;
  width: 90%;
}
input{
  font-size: 16px;
  margin-bottom: 5%;
}
button .login{
  margin-top: 5%;
  background-color: #F6BA7B;
  border-radius: 12px;
  width: 30%;
  font-weight: none;
  text-decoration: none;
}
.forgotPwd{
  margin-bottom: 0;
  margin-top: 5px;
}
</style>