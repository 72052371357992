<template>
  <MqResponsive :target="['xs','sm','md']"> 
    <div class="createHeader">
      <!-- user pic, name, clouds -->
      <img src="../assets/logos/Cloud.gif" class="cloud1"/>
      <img src="../assets/logos/Cloud.gif" class="cloud2"/>
      <img src="../assets/logos/Cloud.gif" class="cloud3"/>
      <img src="../assets/logos/Cloud.gif" class="cloud4"/>
      <!-- Beers logo -->
      <img src="../assets/logos/Beer.gif" class="beer1"/>
      <img src="../assets/logos/Beer.gif" class="beer2"/>
      <img src="../assets/logos/Beer.gif" class="beer3"/>

      <!-- TODO Caroussel to choose logo -->
      <img src="../assets/default/Mountains.png" class="picture"/>
      <!-- TODO END -->
    </div>
    <div class="divwhitebcg">
      <form @submit.prevent="createEvent">
        <div class="createText">
          <!-- <h2>Créer</h2> -->
          <button type="submit">Créer</button>
        </div>
        <div class="eventInfos">
          <h2>Nom de l'évenement:</h2>
          <input type="text" v-model="name" placeholder="" required/>
          <h2>Description:</h2>
          <input type="text" v-model="description" placeholder="" required/>
          <h2>Date et Heure:</h2>
          <input type="date" v-model="date" required/>
          <input type="time" v-model="time" required/>
          <h2>Lieu:</h2>
          <input type="text" v-model="lieu" placeholder=""/>
        </div>
      </form>
    </div>


  </MqResponsive>
  <MqResponsive :target="['lg','xl','xxl']">
    <div>
      <h1>Create you very own event !</h1>
      <span v-if="!this.$store.state.token">
        <h2>
          <router-link to="/login">Login</router-link> 
        </h2>
      </span>
      <span v-if="this.$store.state.token">
        <form @submit.prevent="createEvent">
          <input type="text" v-model="name" placeholder="Name" required/>
          <input type="text" v-model="description" placeholder="description" required/>
          <input type="date" v-model="date" required/>
          <input type="time" v-model="time" required/>
          <button type="submit">Create</button>
        </form>
      </span>
    </div>
  </MqResponsive> 
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      name: null,
      description: null,
      date: null,
      time: null,
      lieu: null
    };
  },
  methods: {
    async createEvent() {
      try {
        const response = await axios.post(this.$store.state.apiURI +'event/create', {
          name: this.name,
          description: this.description,
          ownerid: this.$store.state.user.id,
          date: this.date+'T'+this.time,
          location: this.lieu
        },{ 
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.token
          }
        });
        this.$router.push('/');
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style>
.createHeader{
    height: 100%;
    overflow: hidden;
}
.createHeader .cloud1{
    width: 40%;
    position: fixed;
    top: 15%;
    left: 69%;
}
.createHeader .cloud2{
    width: 20%;
    position: fixed;
    top: 5%;
    left: 0%;
    -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.createHeader .cloud3{
    width: 22%;
    position: fixed;
    top: 1%;
    left: 70%;
}
.createHeader .cloud4{
    width: 28%;
    position: fixed;
    top: 25%;
    left: 6%;
}
.createHeader .beer1{
    width: 10%;
    position: fixed;
    top: 15%;
    left: 10%;
    rotate: -25deg;
}
.createHeader .beer2{
    width: 10%;
    position: fixed;
    top: 10%;
    left: 72%;
    rotate: 25deg;
}
.createHeader .beer3{
    width: 10%;
    position: fixed;
    top: 23%;
    left: 58%;
    rotate: -25deg;
}
.createHeader .picture{
    width: 40%;
    border-radius: 50%;
    border: 2px solid;
    margin-top: 30px;
}

.createHeader h1{
    margin: 0;
}

.divwhitebcg .createText{
  position: relative;
  top: -20px;
  left: 50%;
  width: 90px;
}
.createText button{
  position: relative;
  background-color: peachpuff;
  left: -45px;
  padding-top: 0;
  width: 100%;
  border-radius: 25px;
  border: 1px solid;
  font-weight:bolder;
  text-decoration: none;
}
.eventInfos h2{
  padding: 0;
}

</style>