<template>
    <span v-if="!this.isAddPicture">
        <span class="AddPicture">
            <h3  @click="this.isAddPicture = true">Ajouter </h3>
            <img @click="this.isAddPicture = true" src="../assets/icons/remove.png"/>
        </span>
        <span v-if="!this.ImgBig" class="containerPicsEvent">
            <div v-for="(img, index) in images" :key="index" class="containerPics">
                <h4>{{img.name.split('.')[0]}}</h4>
                <img @click="this.currentImage = img; this.ImgBig = true" :src="img.image" class="picturesEvent"/>
            </div>
        </span>
        <span v-if="this.ImgBig" class="containerPicsEvent">
            <button class="returnBTN" @click="this.currentImage = null; this.ImgBig = false"><img src="../assets/icons/arrow.png"/></button>
            <img :src="currentImage.image" class="imgBig"/>
        </span>
    </span>
    <span v-if="this.isAddPicture">
        <h2> Ajoutez une photo !</h2>
        <form @submit.prevent="AddPicture">
            <input type="file" @change="onFileChanged($event)" required/>
            <button type="submit">Ajouter</button>
        </form>
        <button @click="this.isAddPicture = false">Retour</button>
    </span>
</template>
<script>
import axios from 'axios';
export default {
    data(){
    return{
        imagesNames: [],
        images:[],
        currentImage: null,
        logo1: null,
        ImgBig: false,
        isAddPicture: false,
        toAddImage: null,
        timer: null,
    }
  },
  created() {
    this.ListPhotosName();
  },
  mounted: function () {
        this.timer = setInterval(() => {
            this.ListPhotosName()
        }, 10000)
    },
    beforeUnmount(){
        clearInterval(this.timer)
    },
  methods: {
    async ListPhotosName(){
        try {
          const uri = this.$store.state.apiURI +'event/pictures/get';
            const response = await axios.post(uri,{
                    eventid: this.$store.state.event.id
            },{
                headers: {
                    'Authorization': 'Bearer ' + this.$store.state.token
                }
            });
                this.imagesNames = response.data
                console.log(this.imagesNames)
                this.imagesNames.forEach(image => {
                    if(this.images.find(item => item.name === image.name)){
                        console.log("image already downloaded")
                    }else{
                        this.getPhoto(image.name);
                    }
            });
        } catch (error) {
            console.error(error);
        }
    },
    async getPhoto(name){
        try {
            const uri = this.$store.state.apiURI +'event/pictures/fetch';
            await axios.post(uri,{
                    eventid: this.$store.state.event.id,
                    picname: name,
            },{
                headers: {
                    'Authorization': 'Bearer ' + this.$store.state.token,
                },
                responseType: 'blob'
            }).then(response => {
                const urlCreator = window.URL || window.webkitURL
                this.logo1 = urlCreator.createObjectURL(response.data)
                this.images.push( {
                    name: name,
                    image: urlCreator.createObjectURL(response.data)
                })
            })
            // console.log('image : ' + )
        } catch (error) {
            console.error(error);
        }
    },
    async AddPicture(){
        try {
            var bodyFormData = new FormData();
            bodyFormData.append('file', this.toAddImage); 
            const uri = this.$store.state.apiURI +'event/pictures/upload';
            const response = await axios.post(uri,bodyFormData,{
                headers: {
                    'Authorization': 'Bearer ' + this.$store.state.token,
                    'eventid': this.$store.state.event.id
                }
            });
            console.log(response);
            if(response.status === 200){
                this.isAddPicture = false;
            }
            else{
                console.log("zeubi")
            }
        } catch (error) {
            console.error(error);
        }
    },
    onFileChanged($event) {
            const target = $event.target;
            if (target && target.files) {
                this.toAddImage = target.files[0];
            }
        }
    }
}
</script>
<style>
.AddPicture{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-right: 5px;
}
.AddPicture h3{
    top: 8px;
    margin-right: 5px;
}
.AddPicture img{
    rotate: 45deg;
    width: 25px;
    height: 25px;
    /* position: absolute; */
    margin-top: 22px;
}
.containerPicsEvent{
    display: flex;
    flex-wrap: wrap;
    /* gap: 25px; */
    align-items: center;
    justify-content: space-evenly;
}

.containerPicsEvent .containerPics{

}
.containerPics h4{
    margin-top: 10px;
    margin-bottom: 10px;
}
.containerPics .picturesEvent{
    height: 150px;
    border-radius: 25px;
}
.containerPicsEvent .imgBig{
    width: 70%;
}
.containerPicsEvent .returnBTN{
    top:25px
}
</style>