<template>
    <MqResponsive :target="['xs','sm','md']"> 
      <div class="registerheader">

        <img src="../assets/logos/Cloud.gif" class="cloud1"/>
        <img src="../assets/logos/Cloud.gif" class="cloud2"/>
        <img src="../assets/logos/Cloud.gif" class="cloud3"/>
        <img src="../assets/logos/Cloud.gif" class="cloud4"/>

        <div class="profilelogo">
          <img src="../assets/logos/Logo_Sans.png" class="pp"/>
          <h1 id="username">Je m'inscris</h1>
        </div>
      </div>
      <div class="divwhitebcg createbcg">
        <form @submit.prevent="register" class="registerbody">
        <div class="createText">
          <!-- <h2>Créer</h2> -->
          <button type="submit">Valider</button>
        </div>
        <h2>Prénom - Nom:</h2>
        <div class="nameContainer">
          <input class="name" type="text" v-model="name" placeholder="Prénom" required/>
          <input class="name" type="text" v-model="lastname" placeholder="Nom" required/>
        </div>
        <h2>Pseudo:</h2>
          <input type="text" v-model="surname" placeholder="Pseudo" required/>
        <h2>Email:</h2>
          <input type="email" v-model="email" placeholder="Email" disabled/>
        <h2>Mot de passe:</h2>
          <input type="password" v-model="password" placeholder="1 Maj 1 min 1 caractère special" required/>
          <!-- <input type="file" accept="image/png, image/jpeg" required/> -->
        </form>
      </div>
  </MqResponsive>
  <MqResponsive :target="['lg','xl','xxl']"> 
  {{ this.invitationToken }}
    <div v-if="!this.$store.state.token && this.invitationToken">
      <form @submit.prevent="register">
        <input type="email" v-model="email" placeholder="Email" required/>
        <input type="text" v-model="name" placeholder="Name" required/>
        <input type="text" v-model="lastname" placeholder="Lastname" required/>
        <input type="text" v-model="surname" placeholder="Surname" required/>
        <input type="password" v-model="password" placeholder="Password" required/>
        <button type="submit">Register</button>
      </form>
    </div>
  </MqResponsive>
</template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: this.$route.params.token.split('email:')[1],
      name: '',
      lastname: '',
      surname: '',
      password: '',
      token: '',
      invitationToken: this.$route.params.token.split('email:')[0].replace(":",""),
    };
  },
  methods: {
    async register() {
      try {
        const response = await axios.post(this.$store.state.apiURI +'account/register', {
          email: this.email,
          name: this.name,
          lastname: this.lastname,
          surname: this.surname,
          password: this.password,
          invitationToken: this.invitationToken
        });
        this.token = response.data.token;
        this.$store.commit('setToken', this.token);
        this.$store.commit('setUser', response.data.user);
        this.$router.push({name: 'profile'});
      } catch (error) {
        console.error(error);
      }
    },
  },

};
</script>

<style>
.createbcg{
  height: 70% !important;
  z-index: 3;
  position: relative !important;
  width: 110% !important;
  left: -5% !important;
}
.registerheader{
  height: 100%;
  overflow: hidden;
}
.registerheader .pp{
  width: 80%;
  border-radius: 50%;
  padding-top: 25px;
}
.registerheader h1{
  margin: 0;
}
.registerheader .cloud1{
  width: 30%;
  position: fixed;
  top: 5%;
  left: -9%;
  z-index: 1;
}
.registerheader .cloud2{
  width: 20%;
  position: fixed;
  top: 3%;
  left: 80%;
  z-index: 1;
}
.registerheader .cloud3{
  width: 30%;
  position: fixed;
  top: 25%;
  left: 80%;
  z-index: 1;
}
.registerheader .cloud4{
  width: 20%;
  position: fixed;
  top: 25%;
  left: 10%;
  z-index: 1;
}
.registerbody{
  display: flex;
  flex-direction: column;
}
.registerbody input{
  width: 70%;
  height: 25px;
  margin-left: 15%;
  background-color: rgba(253, 221, 193, 0.759);
  border-radius: 25px;
  border: 1px solid;
  text-align: center;
}
.registerbody h2{
  padding-top: 0 !important; 
}
.registerbody .nameContainer{
  display: flex;
  justify-content: center;
}
.registerbody .name{
  width: 33%;
  margin-left: 5px;
}
</style>