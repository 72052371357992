<template>
    <MqResponsive :target="['xs','sm','md']"> 
        <div class="eventheader">
            <!-- logo, clouds, beers -->
            <img src="../assets/logos/Logo_Sans.png" class="appLogoHome"/>
            <img src="../assets/logos/Cloud.gif" class="cloud1"/>
            <img src="../assets/logos/Cloud.gif" class="cloud2"/>
            <img src="../assets/logos/Cloud.gif" class="cloud3"/>
            <img src="../assets/logos/Cloud.gif" class="cloud4"/>
            <img src="../assets/logos/Cloud.gif" class="cloud5"/>
            <img src="../assets/logos/Cloud.gif" class="cloud6"/>
            <img src="../assets/logos/Cloud.gif" class="cloud7"/>
            <img src="../assets/logos/Cloud.gif" class="cloud8"/>
            <img src="../assets/logos/Cloud.gif" class="cloud9"/>
            <img src="../assets/logos/Cloud.gif" class="cloud10"/>
            <img src="../assets/logos/Beer.gif" class="beer1"/>
            <img src="../assets/logos/Beer.gif" class="beer2"/>
            <img src="../assets/logos/Beer.gif" class="beer3"/>
            <img src="../assets/logos/Beer.gif" class="beer4"/>
            <img src="../assets/logos/Beer.gif" class="beer5"/>
            <img src="../assets/logos/Beer.gif" class="beer6"/>
            <img src="../assets/logos/Beer.gif" class="beer7"/>
            <!-- Return icon -->
            <button class="returnBTN" @click="this.$router.push({name: 'home'})"><img src="../assets/icons/arrow.png"/></button>
        </div>
        <div class="eventinfo">
            <!-- if this.$store.state.event.isPassed is true forbid modification -->
            <!-- nom date lieu -->
            <h2 class="title">{{ this.$store.state.event.name }}</h2>
            <div class="date">
                <h2>{{ this.$store.state.event.date.split('T')[0] }}</h2>
                <h2>{{ this.$store.state.event.date.split('T')[1] }}</h2>
            </div>
            <h2 class="location">Organisé par {{ this.ownerName }}</h2>
            <h2 class="location">{{ this.$store.state.event.location }}</h2>
        </div>
        <ul class="categoryButtonContainer">
            <li id="Invite" class="eventcategorybuttonOn" @click="this.menu = 0; this.changeStyle('Invite')"><h2>Invités</h2></li>
            <li id="Courses" class="eventcategorybutton" @click="this.menu = 1; this.changeStyle('Courses')"><h2>Liste de courses</h2></li>
            <li id="Photos" class="eventcategorybutton" @click="this.menu = 2; this.changeStyle('Photos')"><h2>Photos</h2></li>
        </ul>
        
        <div v-if="this.menu === 0" class="eventguests">
            <GuestList/>
        </div>
        <div v-if="this.menu === 1" class="eventshoppinglist">
            <!-- lise de course -->
            <ShoppingList/>
        </div>
        <div v-if="this.menu === 2" class="eventphotos">
            <Photos/>
        </div>
    </MqResponsive>
    <MqResponsive :target="['lg','xl','xxl']"> 
        <button v-if="!this.editMode" @click="this.editMode = !this.editMode">Edit</button>
        <button v-if="this.editMode" @click="this.editMode = !this.editMode">Done</button>
        <span v-if="!this.editMode">
            <p>id: {{ this.$store.state.event.id }}</p>
            <p>Name: {{ this.$store.state.event.name }}</p>
            <p>Description: {{ this.$store.state.event.description }}</p>
            <p>Location: {{ this.$store.state.event.location }}</p>
            <p>Date: {{ this.$store.state.event.date }}</p>
        
            <span v-if="guests">
                <p>Guest List :</p>
                <p>Surname | Status</p>
                <div v-for="(guest, index) in guests" :key="index">
                    {{ guest.surname }} | {{ guest.status }}
                </div>
            </span> 
            <form @submit.prevent="invite">
                <input type="text" v-model="surname" placeholder="Surname" required/>
                <button type="submit">Invite</button>
            </form>
            <div v-if="this.alreadyInvited == true">
            Guest already invited
            </div>
            <div> 
                <Chat/>
                <ShoppingList/>
            </div>
        </span>
        <span v-if="this.editMode">
            <p>id: {{ this.$store.state.event.id }}</p>
            <p>Name: {{ this.$store.state.event.name }} <button v-if="!this.editName" @click="this.editName = true">Rename</button></p> 
            <form @submit.prevent="editEvent('name',this.$store.state.event.name,this.name)" v-if="this.editName">
                <input type="text" v-model="name" placeholder="Name" required/>
                <button type="submit">Edit</button> | 
                <button @click="this.editName = false">Cancel</button>
            </form>
            <p>Description: {{ this.$store.state.event.description }} <button v-if="!this.editdescription" @click="this.editdescription = true">Rename</button></p> 
            <form @submit.prevent="editEvent('description',this.$store.state.event.description,this.description)" v-if="this.editdescription">
                <input type="text" v-model="description" placeholder="description" required/>
                <button type="submit">Edit</button> | 
                <button @click="this.editdescription = false">Cancel</button>
            </form>
            <p>Location: {{ this.$store.state.event.location }} <button v-if="!this.editlocation" @click="this.editlocation = true">Rename</button></p> 
            <form @submit.prevent="editEvent('location',this.$store.state.event.location,this.location)" v-if="this.editlocation">
                <input type="text" v-model="location" placeholder="Location" required/>
                <button type="submit">Edit</button> | 
                <button @click="this.editlocation = false">Cancel</button>
            </form>
            <p>Date: {{ this.$store.state.event.date }} <button v-if="!this.editdate" @click="this.editdate = true">Rename</button></p> 
            <form @submit.prevent="editEvent('date',this.$store.state.event.date,this.date+'T'+this.time)" v-if="this.editdate">
                <input type="date" v-model="date" placeholder="Date" required/>
                <input type="time" v-model="time" placeholder="Time" required/>
                <button type="submit">Edit</button> | 
                <button @click="this.editdate = false">Cancel</button>
            </form>
        </span>
    </MqResponsive>
</template>
<script>
import axios from 'axios';
import Chat from '@/components/Chat.vue'
import ShoppingList from '@/components/ShoppingList.vue'
import GuestList from '@/components/GuestList.vue'
import Photos from '@/components/Photos.vue'

export default {
    data() {
        return {
            surname: '',
            guests: null,
            ownerName: null,
            editMode: false,
            editName: false,
            editdescription: false,
            editlocation: false,
            editdate: false,
            
            name: null,
            description: null,
            location: null,
            date: null,
            time: null,
            menu: 0,
        };
    },
    created() {
        this.getOwnerName();
    },
    methods:{
        async getOwnerName(){
            try {
                const resp = await axios.post(this.$store.state.apiURI +'friends/getname', {
                    userid: this.$store.state.event.owner,
                },{
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.state.token
                    }
                });
                this.ownerName = (resp.data[0])[0].surname;
            } catch (error) {
                console.error(error);
            }
        },
        async editEvent(property, oldval, newval){
            try {
                console.log(property + " "+ oldval+ " "+ newval);
                const resp = await axios.post(this.$store.state.apiURI +'event/edit', {
                    userid: this.$store.state.user.id,
                    eventid: this.$store.state.event.id,
                    propertyName: property,
                    oldValue: oldval,
                    newValue: newval,
                },{
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.state.token
                    }
                });
                this.$store.commit('setEvent', resp.data);
            } catch (error) {
                console.error(error);
                
            }
        },
        changeStyle(id)
        {
            switch (id) {
                case "Invite":
                    document.getElementById(id).setAttribute("class", "eventcategorybuttonOn");
                    document.getElementById("Courses").setAttribute("class", "eventcategorybutton");
                    document.getElementById("Photos").setAttribute("class", "eventcategorybutton");
                    break;
                case "Courses":
                    document.getElementById(id).setAttribute("class", "eventcategorybuttonOn");
                    document.getElementById("Invite").setAttribute("class", "eventcategorybutton");
                    document.getElementById("Photos").setAttribute("class", "eventcategorybutton");
                    break;
                case "Photos":
                    document.getElementById(id).setAttribute("class", "eventcategorybuttonOn");
                    document.getElementById("Courses").setAttribute("class", "eventcategorybutton");
                    document.getElementById("Invite").setAttribute("class", "eventcategorybutton");
                    break;
                default:
                    break;
            }
        }
    },
    components:{
        Chat,
        ShoppingList,
        GuestList,
        Photos
    }
}
</script>

<style>
.eventheader .cloud1{
  width: 35%;
  position: fixed;
  left:75%;
  top:10%;
  z-index: 1;
}
.eventheader .cloud2{
  width: 50%;
  position: fixed;
  left: -35%;
  top: -2%;
  z-index: 1;
}
.eventheader .cloud3{
  width: 20%;
  position: fixed;
  left: 5%;
  top: 25%;
  z-index: 1;
}
.eventheader .cloud4{
  width: 30%;
  position: fixed;
  left: 30%;
  top: 35%;
  z-index: 1;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.eventheader .cloud5{
  width: 25%;
  position: fixed;
  left: -5%;
  top: 53%;
  z-index: 1;
}
.eventheader .cloud6{
  width: 28%;
  position: fixed;
  left: 80%;
  top: 60%;
  z-index: 1;
}
.eventheader .cloud7{
  width: 35%;
  position: fixed;
  left: 15%;
  top: 75%;
  z-index: 1;
}
.eventheader .cloud8{
  width: 20%;
  position: fixed;
  left: 75%;
  top: 83%;
  z-index: 1;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.eventheader .cloud9{
  width: 25%;
  position: fixed;
  left: 85%;
  top: 42%;
  z-index: 1;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.eventheader .cloud10{
  width: 20%;
  position: fixed;
  left: 40%;
  top: 60%;
  z-index: 1;
}
.eventheader .beer1{
  width: 10%;
  position: fixed;
  left: 70%;
  top: 4%;
  z-index: 1;
  rotate: 15deg;
}
.eventheader .beer2{
  width: 10%;
  position: fixed;
  left: 24%;
  top: 19%;
  z-index: 1;
  rotate: -15deg;
}
.eventheader .beer3{
  width: 10%;
  position: fixed;
  left: 70%;
  top: 25%;
  z-index: 1;
  rotate: 10deg;
}
.eventheader .beer4{
  width: 10%;
  position: fixed;
  left: 70%;
  top: 50%;
  z-index: 1;
  rotate: -6deg;
}
.eventheader .beer5{
  width: 10%;
  position: fixed;
  left: 20%;
  top: 65%;
  z-index: 1;
  rotate: 10deg;
}
.eventheader .beer6{
  width: 10%;
  position: fixed;
  left: 10%;
  top: 40%;
  z-index: 1;
  rotate: 10deg;
}
.eventheader .beer7{
  width: 10%;
  position: fixed;
  left: 65%;
  top: 75%;
  z-index: 1;
  rotate: 18deg;
}
.eventheader .returnBTN{
    position:absolute;
    top: 45px;
    left:25px;
    z-index: 5;
}
.returnBTN img{
    width: 25px;
}

/* ---------------------------------------- */
.eventinfo{
    background-color: white;
    border: 1px solid;
    border-radius: 12px;
    z-index: 2;
    position: relative;
}
.eventinfo .title{
    color: #FF9627;
    margin-bottom: 10px;
}
.eventinfo .date{
    display: flex;
    justify-content: space-around;
    padding-top: 0;
}
.date h2{
    margin-top: 0;
    margin-bottom: 10px;
}
.eventinfo .location{
    margin-top: 0;
    margin-bottom: 10px;
}

/* ---------------------------------------- */
.categoryButtonContainer{
    display: flex;
    flex-direction: row;
    padding-left: 0;
    z-index: 2;
    position: relative;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 0;
}
.categoryButtonContainer .eventcategorybutton{
    background-color: white;
    border: 1px solid;
    border-radius: 12px;
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.categoryButtonContainer .eventcategorybuttonOn{
    background-color: white;
    border: 1px solid;
    border-radius: 12px;
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.eventcategorybutton h2{
    margin: 0;
    /* margin-top: 5px; */
    margin-bottom: 5px;
}
.eventcategorybuttonOn h2{
    margin: 0;
    /* margin-top: 5px; */
    margin-bottom: 5px;
    color: #FF9627;
}
/* ---------------------------------------- */
.eventguests{
    background-color: white;
    border: 1px solid;
    border-radius: 12px;
    z-index: 2;
    position: relative;
    width: 100%;
    top: 10px;
    margin-bottom: 95px;
}
/* ---------------------------------------- */
.eventshoppinglist{
    background-color: white;
    border: 1px solid;
    border-radius: 12px;
    z-index: 1;
    position: relative;
    width: 100%;
    top: 10px;
    margin-bottom: 95px;
}
/* ---------------------------------------- */
.eventphotos{
    background-color: white;
    border: 1px solid;
    border-radius: 12px;
    z-index: 1;
    position: relative;
    width: 100%;
    top: 10px;
    margin-bottom: 95px;
}
</style>