<template>
    <MqResponsive :target="['xs','sm','md']"> 
        <div class="profileHeader">
            <!-- user pic, name, clouds -->
            <img src="../assets/logos/Cloud.gif" class="cloud1"/>
            <img src="../assets/logos/Cloud.gif" class="cloud2"/>
            <div class="profilelogo">
                <img src="../assets/default/Mountains.png" class="pp"/>
                <h1 id="username">{{ this.$store.state.user.surname }}</h1>
            </div>
        </div>
        <div class="divwhitebcg">
            <!-- Infos, disconnect -->
            <button @click="disconnect" class="btndisconnect"><img src="../assets/icons/on-off-button.png" class="onoff"/></button>
            <button @click="this.$router.push({name: 'settings'})" class="btnSettings"><img src="../assets/icons/settings.png" class="settings"/></button>
            <h2>Adresse Mail:</h2>
            <h3>{{ this.$store.state.user.email }}</h3>
            <span v-if="this.friends">
                <h2>
                    <button @click="this.DisplayFirends = !this.DisplayFirends" style="text-decoration: none;">
                        -- Friend's: --
                    </button>
                </h2>
                <div v-if="this.DisplayFirends == true" v-for="(friend, index) in this.friends" :key="index">
                    <h3>{{ friend.surname }}  <span v-if="friend.status === 'Accepted'"><img src="../assets/icons/Friend.png" style="width: 25px;"/></span> <span v-if="friend.status === 'Requested'">⌛</span></h3>
                </div>
            </span>
            <span v-if="this.requests">
            <h2>-- Invitations --</h2>
            <div v-for="(req, index) in this.requests" :key="index">
                {{ req.surname }} | {{ req.email }}
                <button @click="this.acceptFriend(req.email);">Accept</button> | 
                <button @click="this.denyFriend(req.email);">Deny</button>
            </div>
            </span>
            <h2><button @click="this.DisplayInvite = true">Inviter un ami</button></h2>
            
        </div>
        <div v-if="this.DisplayInvite == true" class="InviteScreen">
            <!-- TODO move to a component -->
            <h2 class="title">Invite un ami à te rejoindre!</h2>
            <div v-if="this.useEP">
                <!-- Already user Friend's Event -->
                <h3>En utilisant son pseudo:</h3>
                <form @submit.prevent="invite">
                    <input type="text" v-model="text" placeholder="R2D2" required/>
                    <button type="submit">Inviter!</button>
                </form>
                <h2 v-if="this.alreadyAccount == true">{{ this.InviteError }}</h2>
                <button @click="this.useEP = false">Ton ami n'a pas encore de compte ? Invite le en cliquant ici</button>
            </div>
            <div v-if="!this.useEP">
                <!-- not on Friend's Event -->
                <h3>En utilisant son adresse mail:</h3>
                <form @submit.prevent="emailinvite">
                    <input type="email" v-model="text" placeholder="DarkVador@Empire.org" required/>
                    <button type="submit">Inviter!</button>
                </form>
                <h2 v-if="this.alreadyAccount == true">Ton ami a deja un compte!</h2>
                <h2 v-if="this.InviteSent == true">Invitation envoyée</h2>
                <button @click="this.useEP = true">Ton ami a deja un compte ? Clique ici</button>
            </div>
            <h2><button @click="this.DisplayInvite = false">Annuler</button></h2>
        </div>
    </MqResponsive>

    <MqResponsive :target="['lg','xl','xxl']"> 
        id: {{ this.$store.state.user.id }}
        Name: {{ this.$store.state.user.name }}
        Lastname: {{ this.$store.state.user.lastname }}
        Surname: {{ this.$store.state.user.surname }}
        Email: {{ this.$store.state.user.email }}
        <span v-if="this.friends">
            <div>-- Firends: --</div>
            <div v-for="(friend, index) in this.friends" :key="index">{{ friend.surname }} | {{ friend.email }} | {{ friend.status }}</div>
        </span>
        <span v-if="this.requests">
            <div>-- Firends Requests: --</div>
            <div v-for="(req, index) in this.requests" :key="index">
                {{ req.surname }} | {{ req.email }} | {{ req.status }} 
                <button @click="this.acceptFriend(req.email);">Accept</button> | 
                <button @click="this.denyFriend(req.email);">Deny</button>
            </div>
        </span>
        <span v-if="!this.useEP">
            <!-- If user doesn't already use EventPlanner -->
            <form @submit.prevent="emailinvite">
                <input type="email" v-model="text" placeholder="Email" required/>
                <button type="submit">Invite</button>
            </form>
            <button @click="this.useEP = true">Your friend already use EventPlanner ? Click here to Invite him</button>
        </span> 
        <span v-if="this.useEP">
            <!-- If user already use EventPlanner -->
            <form @submit.prevent="invite">
                <input type="text" v-model="text" placeholder="Surname" required/>
                <button type="submit">Invite</button>
            </form>
            <button @click="this.useEP = false">Your friend doesn't use EventPlanner ? Click here to Invite him</button>
        </span>
    </MqResponsive>
</template>

<script>
import axios from 'axios';




export default {
    data() {
    return {
        text: '',
      guests: null,
      friends: null,
      useEP: true,
      requests: null,
      DisplayInvite: false,
      DisplayFirends: true,
      alreadyAccount: false,
      InviteSent: false,
      notexistAccount: false,
      InviteError: null,
      timer: null,
    };
  },
  created() {
    this.Refresh();
    
    },
  mounted: function () {
    this.timer = setInterval(() => {
        this.Refresh()
    }, 5000)
    },
    beforeUnmount(){
        clearInterval(this.timer)
    },
  methods:{
        async invite(){
            try {
                const resp = await axios.post(this.$store.state.apiURI +'friends/add', {
                    userid: this.$store.state.user.id,
                    friendsurname: this.text,
                },{
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.state.token
                    }
                });
                this.text= '';
            console.log(resp);
            switch (resp.data) {
                case  "friendship already exist":
                    this.InviteError = "c'est deja ton ami";
                    this.alreadyAccount = true;
                    setTimeout(() => this.alreadyAccount = false, 1500);
                    break;
                case  "No User match this username":
                    this.InviteError = "Cet utilisateur n'existe pas";
                    this.alreadyAccount = true;
                    setTimeout(() => this.alreadyAccount = false, 1500);
                    break;
                default:
                    break;
            }
            this.listFriends();
            } catch (error) {
                console.error(error);
            }
        },
        async emailinvite(){
            try {
                const resp = await axios.post(this.$store.state.apiURI +'friends/invite', {
                    userid: this.$store.state.user.id,
                    friendemail: this.text,
                },{
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.state.token
                    }
                });
                this.text = '';
            console.log(resp);
            switch (resp.data) {
                case "User Already Exist":
                    this.alreadyAccount = true;
                    setTimeout(() => this.alreadyAccount = false, 1500);
                    break;
            
                case "Invitation Sent":
                    this.InviteSent = true;
                    setTimeout(() => this.InviteSent = false, 1500);
                    break;
            
                default:
                    break;
            }
            
            this.listFriends();
            } catch (error) {
                console.error(error);
            }
        },
        async listFriends(){
            try {
                const resp = await axios.post(this.$store.state.apiURI +'friends/list', {
                    userid: this.$store.state.user.id
                },{
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.state.token
                    }
                });
                this.friends= resp.data[0]; 
            } catch (error) {
                console.error(error);
            }
        },
        async listFriendsRequest(){
            try {
                const resp = await axios.post(this.$store.state.apiURI +'friends/listrequest', {
                    userid: this.$store.state.user.id
                },{
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.state.token
                    }
                });
                this.requests= resp.data[0]; 
            } catch (error) {
                console.error(error);
            }
        },
        async acceptFriend(friendemail){
            try {
                const resp = await axios.post(this.$store.state.apiURI +'friends/accept', {
                    userid: this.$store.state.user.id,
                    friendemail: friendemail
                },{
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.state.token
                    }
                });
                this.requests= resp.data[0]; 
                this.Refresh();
            } catch (error) {
                console.error(error);
            }
        },
        async denyFriend(friendemail){
            try {
                const resp = await axios.post(this.$store.state.apiURI +'friends/deny', {
                    userid: this.$store.state.user.id,
                    friendemail: friendemail
                },{
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.state.token
                    }
                });
                this.requests= resp.data[0]; 
                this.Refresh();
            } catch (error) {
                console.error(error);
            }
        },
        async Refresh(){
            this.listFriends();
            this.listFriendsRequest();
        },
        disconnect() {
            this.$store.commit('setToken', null);
            this.$store.commit('setUser', null);
            this.$router.push({name: 'home'});
        },
        // async lettering (text){
        //     var str = text;
        //     // node.innerHTML='';
        //     var openTag = '<span>';
        //     var closeTag = '</span>';
        //     var newHTML = openTag;
        //     var closeTags = closeTag;
        //     for(var i=0,iCount=str.length;i<iCount;i++){
        //         newHTML+=str[i]+openTag;
        //         closeTags+=closeTag;
        //     }
        //     var tag_id = document.getElementById('username');
        //     console.log(tag_id)
        //     tag_id.innerHTML = newHTML+closeTags;
        // }
    }
}
</script>

<style>
.profileHeader{
    height: 100%;
    overflow: hidden;
}
.profileHeader .cloud1{
    width: 50%;
    position: fixed;
    top: 11%;
    left: 80%;
}
.profileHeader .cloud2{
    width: 50%;
    position: fixed;
    top: 3%;
    left: -25%;
}
.profileHeader .pp{
    width: 40%;
    border-radius: 50%;
    border: 2px solid;
}
.profileHeader h1{
    margin: 0;
}
/* .profilelogo h1 {
  position: fixed;
  width: 20px;
  left: 15vh;
  top: 1%;
  transform-origin: bottom center;
  transform: rotate(-25deg);
} */
/* .profilelogo h1 span {
    height: 200px;
  position: absolute;
  width: 20px;
  left: 0;
  top: 0;
  transform-origin: bottom center;
  transform: rotate(5deg);
} */


div .btndisconnect{
    width: fit-content;
    position: absolute;
    right: 40px;
    top: 20px;
    width: 7%;
}
div .btnSettings{
    width: fit-content;
    position: absolute;
    left: 40px;
    top: 20px;
    width: 7%;
}
button .onoff{
    width: 100%;
}
button .settings{
    width: 100%;
}
.divwhitebcg{
    background-color: white;
    position: fixed;
    bottom: -48px;
    left: 0%;
    height: 75%;
    border-radius: 48px;
    border: 1px solid;
    border-color: black;
    width: 100%;
}
.divwhitebcg h2{
    padding-top: 20px;
    
    margin: 0;
}
.divwhitebcg h3{
    margin: 0;
}
.InviteScreen{
    background-color: white;
    position: fixed;
    top: 40%;
    left: 2.5%;
    border-radius: 48px;
    border: 1px solid;
    border-color: black;
    width: 95%;
}
.InviteScreen .title{
    color: #FF9627;
}
</style>