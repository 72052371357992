<template>
    <MqResponsive :target="['xs','sm','md']"> <!-- Mobile -->

    <button class="returnBTN" @click="this.$router.push({name: 'settings'})"><img src="../assets/icons/arrow.png"/></button>
        <h2 class="CreditTitre">Notre Équipe:</h2>
    <div class="laTeam">
        <div class="user">
            <img class="userimg" src="../assets/team/Maeva.jpeg"/>
            <h2>Maéva Hemon</h2>
            <h2>Direction artistique</h2>
            <div class="reseaux">
                <a href="https://www.instagram.com/ohla.beaute/" target="_blank">
                    <img class="imgreseaux" src="../assets/icons/instagram.png"/>
                </a>
                <a href="https://drive.google.com/drive/folders/1Yfm59syb24lxfz-tV8e-xyMh00uu-EJJ" target="_blank">
                    <img class="imgreseaux" src="../assets/icons/portfolio.png"/>
                </a>
                <a href="https://www.linkedin.com/in/maeva-hemon-a3825015b/" target="_blank">
                    <img class="imgreseaux" src="../assets/icons/LinkedIn.png"/>
                </a>
            </div>
        </div>
        <div class="user">
            <img class="userimg" src="../assets/team/vincent.jpeg"/>
            <h2>Vincent Vié</h2>
            <h2>Développement</h2>
            <div class="reseaux">
                <a href="https://www.instagram.com/vincent.vie/" target="_blank"><img class="imgreseaux" src="../assets/icons/instagram.png"/></a>
                <a href="#/credits" target="_blank"><img class="imgreseaux" src="../assets/icons/portfolio.png"/></a>
                <a href="https://www.linkedin.com/in/vincent-vié/" target="_blank"><img class="imgreseaux" src="../assets/icons/LinkedIn.png" @click=""/></a>
            </div>
        </div>
    </div>
    <div class="flaticons">
        <h2 class="titre">Les icons utilisés:</h2>
        <a href="https://www.flaticon.com/free-icons/off" target="_blank" title="off icons">Off icons created by Freepik - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/settings" target="_blank" title="settings icons">Settings icons created by Gregor Cresnar Premium - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/user" target="_blank" title="user icons">User icons created by uicon - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/home-button" target="_blank" title="home button icons">Home button icons created by Freepik - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/profile" target="_blank" title="profile icons">Profile icons created by Freepik - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/create" target="_blank" title="create icons">Create icons created by Icongeek26 - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/quit" target="_blank" title="quit icons">Quit icons created by Pixel perfect - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/previous" target="_blank" title="previous icons">Previous icons created by pojok d - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/calendar" target="_blank" title="calendar icons">Calendar icons created by Anggara - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/arrow" target="_blank" title="arrow icons">Arrow icons created by Kirill Kazachek - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/portfolio" target="_blank" title="portfolio icons">Portfolio icons created by Freepik - Flaticon</a>
    </div>
</MqResponsive>
<MqResponsive :target="['lg','xl','xxl']"> <!-- Tablet -->
    <div class="cloudsCreditsPC">
        <img src="../assets/logos/Cloud.png" class="cloud1"/>
        <img src="../assets/logos/Cloud.png" class="cloud2"/>
        <img src="../assets/logos/Cloud.png" class="cloud3"/>
        <img src="../assets/logos/Cloud.png" class="cloud4"/>
        <img src="../assets/logos/Cloud.png" class="cloud5"/>
        <img src="../assets/logos/Cloud.png" class="cloud6"/>
    </div>

    <button class="returnBTN" @click="this.$router.push({name: 'home'})"><img src="../assets/icons/arrow.png"/></button>
        <h2 class="CreditTitrePC">Notre Équipe:</h2>
    <div class="laTeamPC">
        <div class="userPC">
            <img class="userimg" src="../assets/team/Maeva.jpeg"/>
            <h2>Maéva Hemon</h2>
            <h2>Direction artistique</h2>
            <div class="reseauxPC">
                <a href="https://www.instagram.com/ohla.beaute/" target="_blank">
                    <img class="imgreseaux" src="../assets/icons/instagram.png"/>
                </a>
                <a href="https://drive.google.com/drive/folders/1Yfm59syb24lxfz-tV8e-xyMh00uu-EJJ" target="_blank">
                    <img class="imgreseaux" src="../assets/icons/portfolio.png"/>
                </a>
                <a href="https://www.linkedin.com/in/maeva-hemon-a3825015b/" target="_blank">
                    <img class="imgreseaux" src="../assets/icons/LinkedIn.png"/>
                </a>
            </div>
        </div>
        <div class="userPC">
            <img class="userimg" src="../assets/team/vincent.jpeg"/>
            <h2>Vincent Vié</h2>
            <h2>Développement</h2>
            <div class="reseauxPC">
                <a href="https://www.instagram.com/vincent.vie/" target="_blank"><img class="imgreseaux" src="../assets/icons/instagram.png"/></a>
                <a href="#/credits" target="_blank"><img class="imgreseaux" src="../assets/icons/portfolio.png"/></a>
                <a href="https://www.linkedin.com/in/vincent-vié/" target="_blank"><img class="imgreseaux" src="../assets/icons/LinkedIn.png" @click=""/></a>
            </div>
        </div>
    <div class="flaticons">
        <h2 class="titre">Les icons utilisés:</h2>
        <a href="https://www.flaticon.com/free-icons/off" target="_blank" title="off icons">Off icons created by Freepik - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/settings" target="_blank" title="settings icons">Settings icons created by Gregor Cresnar Premium - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/user" target="_blank" title="user icons">User icons created by uicon - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/home-button" target="_blank" title="home button icons">Home button icons created by Freepik - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/profile" target="_blank" title="profile icons">Profile icons created by Freepik - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/create" target="_blank" title="create icons">Create icons created by Icongeek26 - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/quit" target="_blank" title="quit icons">Quit icons created by Pixel perfect - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/previous" target="_blank" title="previous icons">Previous icons created by pojok d - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/calendar" target="_blank" title="calendar icons">Calendar icons created by Anggara - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/arrow" target="_blank" title="arrow icons">Arrow icons created by Kirill Kazachek - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/portfolio" target="_blank" title="portfolio icons">Portfolio icons created by Freepik - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/alert" title="alert icons">Alert icons created by Freepik - Flaticon</a>
    </div>
    <div class="testeursPC">
        <h2 class="titre">Les testeurs:</h2>
        <h3 class="user">Manchot</h3>
        <h3 class="user">Ryckers</h3>
    </div>
</div>
</MqResponsive>
</template>
<style>
.CreditTitre{
    padding-top: 5%;
    text-align: right;
    margin-right: 15px;
}
.laTeam{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: 15px;
}
.laTeam .user{
    background-color: white;
    border: 1px solid;
    border-radius: 25px;
}
.user h2{
    margin: 0;
    margin-bottom: 5px;
}
.user .userimg{
    margin-top: 10%;
    width: 80%;
    border-radius: 50%;
}

.user .reseaux{
    margin-top: 15px;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
}
.reseaux .imgreseaux{
    align-self: center;
    margin: 5px;
    margin-top: 0;
    width: 80%;
}
.flaticons{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid;
    border-radius: 25px;
    margin-bottom: 70px;
}
.flaticons .titre{
    text-align: left;
    margin-left: 15px;
    margin-top: 5px;
}




.cloudsCreditsPC .cloud1{
    width: 25%;
    position: fixed;
    left: 65%;
    top: -10%;
    z-index: 1;
}.cloudsCreditsPC .cloud2{
    width: 12%;
    position: fixed;
    left: 5%;
    top: 5%;
    z-index: 1;
}.cloudsCreditsPC .cloud3{
    width: 20%;
    position: fixed;
    left: -3%;
    top: 75%;
    z-index: 1;
}.cloudsCreditsPC .cloud4{
    width: 10%;
    position: fixed;
    left: 45%;
    top: 75%;
    z-index: 1;
}.cloudsCreditsPC .cloud5{
    width: 20%;
    position: fixed;
    left: 90%;
    top: 65%;
    z-index: 1;
}.cloudsCreditsPC .cloud6{
    width: 19%;
    position: fixed;
    left: 65%;
    top: 85%;
    z-index: 1;
}

.CreditTitrePC{
    padding-top: 5%;
    text-align: center;
    margin-right: 15px;
}
.laTeamPC {
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 10;
    position: relative;
}
.laTeamPC .userPC{
    background-color: white;
    border: 1px solid;
    border-radius: 25px;
    width: 20vw;
    margin : 15px;
}
.userPC .userimg{
    margin-top: 10%;
    width: 50%;
    border-radius: 50%;
    border: 5px solid;
}
.userPC .reseauxPC{
    margin-top: 15px;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
}
.reseauxPC .imgreseaux{
    align-self: center;
    margin: 5px;
    margin-top: 0;
    width: 50%;
}
.laTeamPC .flaticons{
    margin-bottom: 15px;
}
.laTeamPC .testeursPC{
    background-color: white;
    border: 1px solid;
    border-radius: 25px;
    width: 20vw;
    margin : 15px;
}
.testeursPC .titre{
    text-align: left;
    margin-left: 15px;
    margin-top: 5px;
}
.testeursPC .user{
    margin: 2px;
    text-align: left;
    margin-left: 15px;
}
</style>