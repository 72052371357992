<template>
  <div class="home">
    <MqResponsive :target="['xs','sm','md']"> <!-- Mobile -->
      <HomeMobile  v-if="!this.$store.state.token"/>
      <span v-if="this.$store.state.token">
        <span class="homeMobile">
          <img src="../assets/logos/Logo_Sans.png" class="appLogoHome"/>
          <img src="../assets/logos/Cloud.gif" class="cloud1"/>
          <img src="../assets/logos/Cloud.gif" class="cloud2"/>
          <img src="../assets/logos/Cloud.gif" class="cloud3"/>
          <img src="../assets/logos/Cloud.gif" class="cloud4"/>
          <img src="../assets/logos/Cloud.gif" class="cloud5"/>
          <img src="../assets/logos/Cloud.gif" class="cloud6"/>
          <img src="../assets/logos/Cloud.gif" class="cloud7"/>
          <img src="../assets/logos/Cloud.gif" class="cloud8"/>
          <img src="../assets/logos/Cloud.gif" class="cloud9"/>
          <img src="../assets/logos/Beer.gif" class="beer1"/>
          <img src="../assets/logos/Beer.gif" class="beer2"/>
          <img src="../assets/logos/Beer.gif" class="beer3"/>
          <img src="../assets/logos/Beer.gif" class="beer4"/>
          <img src="../assets/logos/Beer.gif" class="beer5"/>
          <img src="../assets/logos/Beer.gif" class="beer6"/>
          <img src="../assets/logos/Beer.gif" class="beer7"/>
          <img src="../assets/logos/Beer.gif" class="beer8"/>
          <img src="../assets/logos/Beer.gif" class="beer9"/>
        </span>
        <div class="listEvents">
          <span v-if="this.displayCommingEvents">
          <button @click="this.displayCommingEvents = !this.displayCommingEvents"><img class="previousEventsIco" src="../assets/icons/previous.png"/></button>
            <h2>Agenda des copains</h2>
            <!-- <EventsList v-if="this.$store.state.token" target="byowner"/> -->
            <EventsList v-if="this.$store.state.token" target="accepted" passed="false"/>
            <Invitations v-if="this.$store.state.token"/>
          </span>
          <span v-if="!this.displayCommingEvents">
          <button @click="this.displayCommingEvents = !this.displayCommingEvents"><img class="previousEventsIco" src="../assets/icons/schedule.png"/></button>
            <h2>Précédents évènements</h2>
            <EventsList v-if="this.$store.state.token" target="accepted" passed="true"/>
          </span>
        </div>
      </span>
    </MqResponsive>

    <MqResponsive :target="['lg','xl','xxl']"> <!-- Tablet -->
      <div class="desc">
        <div class="bcgHomePC">
          <img src="../assets/logos/Cloud.png" class="cld1"/>
          <img src="../assets/logos/Cloud.png" class="cld2"/>
          <img src="../assets/logos/Cloud.png" class="cld3"/>
          <img src="../assets/logos/Cloud.png" class="cld4"/>
          <img src="../assets/logos/Cloud.png" class="cld5"/>
          <img src="../assets/logos/Cloud.png" class="cld6"/>
        </div>
        <img src="../assets/logos/Logo_Avec.png" class="logo"/>
        <h1 class="description">Une super application pour organiser et planifier tes événements !</h1>
        <h2 class="description">Tu veux nous rejoindre ? il faut se faire inviter par un ami ;)</h2>
        <ul class="liste">
          <li>Invite tes amis !</li>
          <li>Crée tes évènements !</li>
          <li>Rempli ta liste de courses !</li>
          <li>Gères les comptes !</li>
        </ul>
      </div>
      <div class="iphone">
        <img src="../assets/Img/iPhone11.png" class="iphone11"/>
        <img src="../assets/Img/CaptureApp_1.png" class="iphonebcg"/>
      </div>
      <button @click="this.$router.push({name: 'credits'})" class="btnCredit">Rencontres nous !</button>
    </MqResponsive>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import EventsList from '@/components/EventsList.vue'
import Invitations from '@/components/Invitations.vue'
import HomeMobile from '@/components/Home.mobile.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    EventsList,
    Invitations,
    HomeMobile
},
data(){
    return{
        displayCommingEvents: true,
    }
  },
}
</script>

<style>
.appLogoHome{
  width: 80%;
  margin-top: 5%;
  position: relative;
  z-index: 3;
}
.homeMobile .cloud1{
  width: 50%;
  position: fixed;
  left:75%;
  top:6%;
  z-index: 1;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.homeMobile .cloud2{
  width: 35%;
  position: fixed;
  left:-10%;
  top: 6%;
  z-index: 1;
}
.homeMobile .cloud3{
  width: 30%;
  position: fixed;
  left: 5%;
  top: 25%;
  z-index: 1;
}
.homeMobile .cloud4{
  width: 30%;
  position: fixed;
  left: 75%;
  top: 55%;
  z-index: 1;
}
.homeMobile .cloud5{
  width: 25%;
  position: fixed;
  left: -5%;
  top: 65%;
  z-index: 1;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.homeMobile .cloud6{
  width: 25%;
  position: fixed;
  left: 75%;
  top: 80%;
  z-index: 1;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.homeMobile .cloud7{
  width: 30%;
  position: fixed;
  left: 30%;
  top: 70%;
  z-index: 1;
}
.homeMobile .cloud8{
  width: 30%;
  position: fixed;
  left: 20%;
  top: 42%;
  z-index: 1;
}
.homeMobile .cloud9{
  width: 30%;
  position: fixed;
  left: 60%;
  top: 30%;
  z-index: 1;
}
.homeMobile .beer1{
  width: 10%;
  position: fixed;
  left: 70%;
  top: 2%;
  z-index: 1;
  rotate: 15deg;
}
.homeMobile .beer2{
  width: 10%;
  position: fixed;
  left: 4%;
  top: 20%;
  z-index: 1;
  rotate: -15deg;
}
.homeMobile .beer3{
  width: 10%;
  position: fixed;
  left: 65%;
  top: 22%;
  z-index: 1;
  rotate: 10deg;
}
.homeMobile .beer4{
  width: 10%;
  position: fixed;
  left: 75%;
  top: 70%;
  z-index: 1;
  rotate: 10deg;
}
.homeMobile .beer5{
  width: 10%;
  position: fixed;
  left: 15%;
  top: 82%;
  z-index: 1;
  rotate: 10deg;
}
.homeMobile .beer6{
  width: 7%;
  position: fixed;
  left: 30%;
  top: 60%;
  z-index: 1;
  rotate: -10deg;
}
.homeMobile .beer7{
  width: 10%;
  position: fixed;
  left: 70%;
  top: 45%;
  z-index: 1;
  rotate: 15deg;
}
.homeMobile .beer8{
  width: 10%;
  position: fixed;
  left: 6%;
  top: 50%;
  z-index: 1;
  rotate: 10deg;
}
.homeMobile .beer9{
  width: 10%;
  position: fixed;
  left: 60%;
  top: 85%;
  z-index: 1;
  rotate: -10deg;
}

.listEvents{
  background-color: white;
  border-radius: 12px;
  border: 1px solid;
  border-color: black;
  position: relative;
  z-index: 2;
  top: 30px;
  width: 100%;
  margin-bottom: 100px;
  padding-bottom: 15px;
}
.listEvents h2{
  color: #FF9627;
  text-align: left;
  padding-left: 5%;
  margin-bottom: 0%;
  margin-top: 0;
}
.listEvents .previousEventsIco{
  width: 35px;
  position: absolute;
  right: 20px;
  top: 20px;

}
.bcgHomePC {
  position: relative;
  /* z-index: 1; */
}
.bcgHomePC .cld1{
  width: 20%;
  left: 35vw;
  position: fixed;
}
.bcgHomePC .cld2{
  width: 15%;
  left: 1vw;
  top: 8vh;
  position: fixed;
}
.bcgHomePC .cld3{
  width: 15%;
  left: 45vw;
  top: 75vh;
  position: fixed;
}
.bcgHomePC .cld4{
  width: 18%;
  left: -5vw;
  top: 65vh;
  position: fixed;
}
.bcgHomePC .cld5{
  width: 15%;
  right: 1vw;
  top: 20vh;
  position: fixed;
}
.bcgHomePC .cld6{
  width: 18%;
  right: -8vw;
  top: 75vh;
  position: fixed;
}
.desc{
  position: fixed;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: 50vw;
}

.desc .logo{
  position: relative;
  width: 60vh;
  top: 6vh;
}

.desc .description{
  width: 80%;
  position: relative;
  top: 5vh;
}
.desc .liste{
  position: relative;
  top: 0;
  margin-bottom: 50px;
  list-style: none;
  font-size: 45px;
}
.liste li:before {
  content: '\2014';
  position: absolute;
  margin-left: -20px;
}

.HomeBigScreen .iphone{
  position: fixed;
  right: 20vw;
  top: 10vh;
  height: 80vh;
  z-index: 10;
}

.HomeBigScreen .iphone11{
  /* height: 60vh; */
  height: 100%;
  position: absolute;
  z-index: 10;
}
.HomeBigScreen .iphonebcg{
  /* height: 100%; */
  position: relative;
  height: 92%;
  left: 11%;
  top: 4%;
  z-index: 5;
}
.btnCredit{
  position: fixed;
  right: 15px;
  top: 15px;
}
</style>
