<template>
    <img src="../assets/logos/Logo_Avec.png" class="appLogo"/>
    <img src="../assets/logos/Cloud.gif" class="cloudLogo cloud1"/>
    <img src="../assets/logos/Cloud.gif" class="cloudLogo cloud2"/>
    <img src="../assets/logos/Cloud.gif" class="cloudLogo cloud3"/>
    <img src="../assets/background/Friend-10.png" class="backgroundLogin"/>
    <h2 v-if="!this.passwordeq">Les mots de passes ne sont pas identiques</h2>
    <div v-if="!this.$store.state.token & !this.passwordChanged" class="hoverimg">
        <h1>Réinitialise ton mot de passe</h1>
        <form @submit.prevent="reset">
          <input type="text" v-model="email" placeholder="Email" disabled /><br/>
          <input type="password" v-model="password" placeholder="Mot de passe" required/><br/>
          <input type="password" v-model="password1" placeholder="Mot de passe" required/><br/>
          <button type="submit" class="login">Reset</button>
        </form>
        <h3 @click="this.$router.push({name: 'login'})">Connexion</h3>
    </div>
    <div v-if="this.passwordChanged" class="hoverimg">
        <h1>Mot de passe réinitialisé</h1>
        <h2>Tu peux te connecter</h2>
        <h3 @click="this.$router.push({name: 'login'})">Connexion</h3>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: this.$route.params.token.split('email:')[1],
      resetToken: this.$route.params.token.split('email:')[0].replace(":",""),
      password: '',
      password1: '',
      passwordeq: true,
      passwordChanged: false,
      token: '',
      incorrectID: false,
      accountDisabled: false,
      resetPassword: false,
    };
  },
  methods: {
    async reset(){
      try {
        if(this.password === this.password1){
            this.passwordeq = true;
            const response = await axios.post(this.$store.state.apiURI +'account/rstpwd', {
                email: this.email,
                password: this.password,
                resetToken: this.resetToken,
            });
            if(response != null){
                if(response.data === "Password Changed"){
                    this.passwordChanged = true;
                }
            }
        }else{
            this.passwordeq = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style>
.appLogo{
  width: 100%; 
  margin-top: 20%;
}
.cloudLogo{
  position:fixed; 
}
.cloud1{
  width: 40%;
  left: 70%;
  top: 3%;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.cloud2{
  width: 30%;
  left: -5%;
  top: 35%;
}
.cloud3{
  width: 30%;
  left: 80%;
  top: 45%;
}
.backgroundLogin{
  margin-left: -25px; 
  margin-right: -55px; 
  width: 112%; 
  position:fixed; 
  bottom: 0; 
  left: 0;
}
.hoverimg{
  position: fixed;
  bottom: 5%;
  width: 90%;
}
input{
  font-size: 16px;
  margin-bottom: 5%;
}
button .login{
  margin-top: 5%;
  background-color: #F6BA7B;
  border-radius: 12px;
  width: 30%;
  font-weight: none;
  text-decoration: none;
}

</style>