<template>
    <MqResponsive :target="['xs','sm','md']"> 
      <span v-if="this.passed === 'true'">
      <div v-for="(event, index) in events" :key="index" class="mobileEventInfo">
        <button v-if="event.isPassed" @click="selectEvent(event)">
            <h4>{{ event.name }}</h4>
              <h5>{{ event.date.split('T')[0] }}</h5>
              <h5>{{ event.date.split('T')[1] }}</h5>
        </button>
      </div>
    </span>
    <span v-if="this.passed === 'false'">
      <div v-for="(event, index) in events" :key="index" class="mobileEventInfo">
        <button v-if="!event.isPassed" @click="selectEvent(event)">
            <h4>{{ event.name }}</h4>
              <h5>{{ event.date.split('T')[0] }}</h5>
              <h5>{{ event.date.split('T')[1] }}</h5>
        </button>
      </div>
    </span>
  </MqResponsive>

</template>

<script>
import axios from 'axios';

export default {
  name: 'EventsList',
  props: {
    target: String,
    passed: String,
  },
  data(){
    return{
        events: null,
    }
  },
  created() {
    this.getEvents();
    console.log(this.passed)
  },
  methods:{
    async checkDate(date){
      const eventdate = new Date(date);
      const today = new Date();
      if(eventdate.getTime() > (today.getTime()+ 24*60*60*1000*-1)){
        // event to come
        console.log(eventdate)
        return false
      }
      else{
      //date is already passed
        console.log(Date())
        return true
      }
    },
    async getEvents() {
        try {
          const uri = this.$store.state.apiURI +'event/' + this.target;
            const response = await axios.post(uri,{
                    id: this.$store.state.user.id
            },{
                headers: {
                    'Authorization': 'Bearer ' + this.$store.state.token
                }
            });
            this.events = response.data
            this.events.forEach(async e => {
              var isPassed = await this.checkDate(e.date);
              e.isPassed = isPassed
              console.log(e.date)
              console.log(e.isPassed)
            });
        } catch (error) {
            console.error(error);
        }
    },
    selectEvent(event){
      this.$store.commit('setEvent', event);
      this.$router.push({name: 'event'})
    }
  }
}
</script>

<style>
.mobileEventInfo{
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
}
.mobileEventInfo button{
  text-decoration: none; 
  width: 100%;
}

.mobileEventInfo button h4{
  margin-top: 12px;
  margin-bottom: 12px;
  text-align: left;
}
.mobileEventInfo button h5{
  text-align: center;
  margin: 0px;
  width: 50%;
  display: inline-block;
}

</style>