import { createStore } from 'vuex'

export default createStore({
  state: {
    token: null,
    user: null,
    event: null,
    // apiURI: 'http://localhost:3000/'
    apiURI: 'https://api.taiky.fr:3000/',
    version: null,
    appversion: '1.5'
  },
  getters: {
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      const d = new Date();
      d.setTime(d.getTime() + (1*24*60*60*1000));
      document.cookie = "token="+token+"; expires="+ d.toUTCString();
    },
    setUser(state, user ){
      state.user = user;
    },
    setEvent(state, event ){
      state.event = event;
    },
    setVersion(state, version ){
      state.version = version;
    },
  },
  actions: {
  },
  modules: {
  }
})
