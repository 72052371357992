<template>
    <MqResponsive :target="['xs','sm','md']"> 
        <!-- liste des guests et add button -->
        <h3 class="addguest" v-if="this.$store.state.event.isPassed === false">
            <button @click="this.listFriends(); this.AddFriendVar = true">Inviter!</button>
        </h3>

        <!-- Display friend List with plus button over friend list -->
        <div class="frndlist" v-if="this.AddFriendVar && this.$store.state.event.isPassed === false">
            <h2 v-if="this.alreadyInvited == true" class="alrdInv">Ami déja invité!</h2>
            <div v-for="(friend, index) in this.friends" :key="index" class="overlayInvite">
                <h2>{{ friend.surname }}</h2> 
                <button @click="this.surname = friend.surname; this.invite();">
                    <img class="invitebtn" src="../assets/icons/remove.png"/>
                </button> 
            </div>
            <h3><button @click="this.AddFriendVar = false">Fini!</button></h3>
        </div>

        <h2 class="title">Liste des invités:</h2>
        <div v-for="(guest, index) in guests" :key="index" class="guestdisplay">
            <h2> {{ guest.surname }}</h2>
            <span v-if="guest.status === 'Accepted'"><img src="../assets/icons/Friend.png"/></span>
            <span v-if="guest.status === 'Pending'">⌛</span>
        </div>
    </MqResponsive>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            surname: '',
            guests: null,
            alreadyInvited: false,
            AddFriendVar: false,
            friends: null,
            timer: null,
        };
    },
    created() {
        this.getGuestList();
    },
    mounted: function () {
        this.timer = setInterval(() => {
            this.getGuestList()
        }, 10000)
    },
    beforeUnmount(){
        clearInterval(this.timer)
    },
    methods:{
        async invite(){
            try {
                //check if the invite surname isn't in the list of guests
                this.alreadyInvited = false;
                this.guests.forEach(guest => {
                    if(guest.surname == this.surname){
                        this.alreadyInvited = true;
                        this.activate();
                    }
                });
                if(this.alreadyInvited == false){ // invite only if guest isn't already invited
                    const resp = await axios.post(this.$store.state.apiURI +'event/invite', {
                        id: this.$store.state.user.id,
                        eventid: this.$store.state.event.id,
                        guestsurname: this.surname,
                    },{
                        headers: {
                            'Authorization': 'Bearer ' + this.$store.state.token
                        }
                    });
                    console.log(resp);
                    this.getGuestList();
                }
            } catch (error) {
                console.error(error);
            }
        },
        async getGuestList(){
            console.log("get guests")
            try {
                const resp = await axios.post(this.$store.state.apiURI +'event/guests', {
                    eventid: this.$store.state.event.id,
                },{
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.state.token
                    }
                });
                this.guests = resp.data;
            } catch (error) {
                console.error(error);
            }
        },
        async listFriends(){
            try {
                const resp = await axios.post(this.$store.state.apiURI +'friends/list', {
                    userid: this.$store.state.user.id
                },{
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.state.token
                    }
                });
                this.friends= resp.data[0]; 
            } catch (error) {
                console.error(error);
            }
        },
        activate() {
            setTimeout(() => this.alreadyInvited = false, 1500);
        }
    }
}
</script>
<style>
.eventguests .title{
    color: #FF9627;
    text-align: left;
    margin-left: 24px;
}
.eventguests .guestdisplay{
    display: flex; 
    justify-content: space-between;
    margin-left: 25px;
    margin-right: 35px;
    margin-bottom: 10px;
}
.guestdisplay h2{
    margin: 0;
}
.guestdisplay img{
    width: 30px;
    margin-top: 5px;
}

.eventguests .addguest{
    position: absolute;
    right: 25px;
}
.frndlist{
    background-color: white;
    border: 1px solid;
    border-radius: 12px;
    z-index: 3;
    width: 90%;
    left: 5%;
    position: absolute;
    top: 10px;
}
.frndlist .overlayInvite{
    display: flex; 
    justify-content: space-between;
    margin-left: 25px;
    margin-right: 35px;
    margin-top: 10px;
}
.overlayInvite h2{
    margin: 0;
}
.overlayInvite h3{
    margin: 0;
}

.alrdInv{
    background-color: white;
    border: 1px solid;
    border-radius: 12px;
    z-index: 4;
    width: 90%;
    left: 5%;
    position: absolute;
    top: 10px;
}
.invitebtn{
    rotate: 45deg;
    width: 20px;
}</style>