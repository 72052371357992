<template>
    <div class="bcgrnd">
      <img src="../assets/logos/Cloud.gif" class="cloud1"/>
      <img src="../assets/logos/Cloud.gif" class="cloud2"/>
      <img src="../assets/logos/Cloud.gif" class="cloud3"/>
      <img src="../assets/logos/Cloud.gif" class="cloud4"/>
      <img src="../assets/logos/Cloud.gif" class="cloud5"/>
    </div>
    <div class="contentHome">
      <img alt="Vue logo" src="../assets/logos/Logo_Sans.png" style="width: 110%; padding-bottom: 10%; padding-top: 10%;">
      <img src="../assets/logos/Beer.png" style="width: 30%; padding-bottom: 6%;"/>
      <h2>J'organise mes apéros entre copains en seulement quelques clics !</h2>
      <button @click="this.$router.push('login')">J'me connecte</button>
    </div>
</template>
<script>
export default {
  name: 'HomeMobile',
  props: {
  },
}
</script>
<style>
.bcgrnd{
  z-index: 1;
}
.bcgrnd .cloud1{
  width: 30%;
  position: fixed;
  left: 75%;
  top: 40%;
  z-index: 1;
}
.bcgrnd .cloud2{
  width: 40%;
  position: fixed;
  left: 70%;
  top: -2%;
  z-index: 1;
}
.bcgrnd .cloud3{
  width: 35%;
  position: fixed;
  left: -5%;
  top: 25%;
  z-index: 1;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.bcgrnd .cloud4{
  width: 35%;
  position: fixed;
  left: 5%;
  top: 75%;
  z-index: 1;
}
.bcgrnd .cloud5{
  width: 30%;
  position: fixed;
  left: 70%;
  top: 85%;
  z-index: 1;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.contentHome {
  position: absolute;
  z-index: 3;
}

</style>