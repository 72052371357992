<template>
    <div class="eventviewheader">
        <button class="returnBTN" @click="this.$router.push({name: 'event'})"><img src="../assets/icons/arrow.png"/></button>
            <img src="../assets/logos/Cloud.gif" class="cloud1"/>
            <img src="../assets/logos/Cloud.gif" class="cloud2"/>
            <img src="../assets/logos/Cloud.gif" class="cloud3"/>
            <img src="../assets/logos/Cloud.gif" class="cloud4"/>
            <img src="../assets/logos/Cloud.gif" class="cloud5"/>
            <img src="../assets/logos/Cloud.gif" class="cloud6"/>
            <img src="../assets/logos/Cloud.gif" class="cloud7"/>
            <img src="../assets/logos/Cloud.gif" class="cloud8"/>
            <img src="../assets/logos/Cloud.gif" class="cloud9"/>
            <img src="../assets/logos/Cloud.gif" class="cloud10"/>
            <img src="../assets/logos/Beer.gif" class="beer1"/>
            <img src="../assets/logos/Beer.gif" class="beer2"/>
            <img src="../assets/logos/Beer.gif" class="beer3"/>
            <img src="../assets/logos/Beer.gif" class="beer4"/>
            <img src="../assets/logos/Beer.gif" class="beer5"/>
            <img src="../assets/logos/Beer.gif" class="beer6"/>
            <img src="../assets/logos/Beer.gif" class="beer7"/>
    </div>
    <div class="eventviewbody">
        <div class="detailAmount blockbcgwhite">
            <span v-for="(user, index) in this.guestsAmount" :key="index">
                <h2 v-if="user.surname != this.$store.state.user.surname">{{ user.surname }}: {{ user.Amount }}€</h2>
            </span>
            <span class="myAmount">
                <h2>Moi: </h2>
                <input type="number" v-if="this.me" v-model="this.me.Amount" @input="this.UpdateAmount()"/>
                <h2>€</h2>
            </span>
            <h2>Total: {{ this.total }}€</h2>
            <h2>Prix par personnes: {{ (Math.round(this.priceperpers * 100))/100 }}</h2>
        </div>
        <div class="blockbcgwhite solde" v-if="this.arr">
            <span v-for="(user, index) in this.arr" :key="index">
                <h2>{{user.debtor}} doit {{(Math.round(user.amount * 100))/100}}€ a {{user.creditor}}</h2>
            </span>
        </div>
    </div>
    
</template>
<script>
import axios from 'axios';
export default {
    data(){
        return{
            list: null,
            guests: null,
            guestsAmount: null,
            me: null,
            total: null,
            priceperpers: null,
            arr: [],
        }
    },
    created() {
        this.getGuestList();
    },
    mounted: function () {
    this.timer = setInterval(() => {
        // this.getAmount()
        this.Generate()
    }, 5000)
    },
    beforeUnmount(){
        clearInterval(this.timer)
    },
    methods:{
        async getGuestList(){
            // console.log("get guests")
            try {
                const resp = await axios.post(this.$store.state.apiURI +'event/guests', {
                    eventid: this.$store.state.event.id,
                },{
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.state.token
                    }
                });
                this.guests = resp.data;
                this.getAmount();
            } catch (error) {
                console.error(error);
            }
        },
        async getAmount(){
            try {
                const resp = await axios.post(this.$store.state.apiURI +'event/guestsamount', {
                    eventid: this.$store.state.event.id,
                },{
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.state.token
                    }
                });
                this.guestsAmount = resp.data;
                this.total = null;
                this.guestsAmount.forEach(user => {
                    if(user.surname === this.$store.state.user.surname){
                        this.me = user;
                    }
                    this.total += user.Amount;
                });
                this.priceperpers = this.total / this.guests.length;
                // this.priceperpers = ((this.total / this.guests.length) *100);
                // this.priceperpers = Math.round(this.priceperpers);
                // this.priceperpers = this.priceperpers/100;
                // this.Generate();
                this.Generate();
            } catch (error) {
                console.error(error);
                
            }
        },
        async UpdateAmount(){
            try {
                const resp = await axios.post(this.$store.state.apiURI +'event/updateguestsamount', {
                    eventid: this.$store.state.event.id,
                    userid: this.$store.state.user.id,
                    amount: this.me.Amount
                },{
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.state.token
                    }
                });
                this.me.Amount = resp.data[0].Amount;
                console.log(resp.data)
            } catch (error) {
                console.error(error);
                
            }
        },
        async Generate(){
            // Créer un objet expenseBalancer qui contient les participants, les dépenses et les remboursements
            var expenseBalancer = {
            participants: [],
            expenses: [],
            refunds: []
            };

            // Ajouter un participant au expenseBalancer
            function addParticipant(name) {
            // Vérifier si le nom est valide et n'existe pas déjà
            if (name && !expenseBalancer.participants.includes(name)) {
                // Ajouter le nom à la liste des participants
                expenseBalancer.participants.push(name);
                // Afficher un message de confirmation
                // console.log(name + " a rejoint le expenseBalancer.");
            } else {
                // Afficher un message d'erreur
                // console.log("Nom invalide ou déjà existant.");
            }
            }

            // Ajouter une dépense au expenseBalancer
            function addExpense(name, amount) {
            // function addExpense(name, amount, description) {
            // Vérifier si le nom est valide et existe dans la liste des participants
            if (name && expenseBalancer.participants.includes(name)) {
                // Vérifier si le montant est valide et positif
                if (amount && amount > 0) {
                // Créer un objet dépense avec le nom, le montant et la description
                var expense = {
                    name: name,
                    amount: amount,
                    // description: description || "Sans description"
                };
                // Ajouter l'objet dépense à la liste des dépenses
                expenseBalancer.expenses.push(expense);
                // Afficher un message de confirmation
                // console.log(name + " a payé " + amount + " € pour " + description + ".");
                // console.log(name + " a payé " + amount + " €.");
                } else {
                // Afficher un message d'erreur
                // console.log("Montant invalide ou négatif.");
                }
            } else {
                // Afficher un message d'erreur
                // console.log("Nom invalide ou inexistant.");
            }
            }

            // Calculer les balances de chaque participant
            function calculateBalances() {
            // Créer un objet balances qui contient le nom et la balance de chaque participant
            var balances = {};
            // Initialiser la balance de chaque participant à zéro
            for (var i = 0; i < expenseBalancer.participants.length; i++) {
                var name = expenseBalancer.participants[i];
                balances[name] = 0;
            }
            // Parcourir la liste des dépenses
            for (var i = 0; i < expenseBalancer.expenses.length; i++) {
                var expense = expenseBalancer.expenses[i];
                // Augmenter la balance du payeur du montant de la dépense
                balances[expense.name] += expense.amount;
                // Diminuer la balance de chaque participant du montant moyen de la dépense
                var average = expense.amount / expenseBalancer.participants.length;
                for (var j = 0; j < expenseBalancer.participants.length; j++) {
                var name = expenseBalancer.participants[j];
                balances[name] -= average;
                }
            }
            // Retourner l'objet balances
            return balances;
            }

            // Afficher les balances de chaque participant
            function showBalances() {
            // Calculer les balances
            var balances = calculateBalances();
            // Parcourir l'objet balances
            for (var name in balances) {
                // Récupérer la balance du participant
                var balance = balances[name];
                // Arrondir la balance à deux décimales
                balance = Math.round(balance * 100) / 100;
                // Afficher la balance du participant avec un signe positif ou négatif selon le cas
                if (balance > 0) {
                // console.log(name + " doit recevoir " + balance + " €.");
                } else if (balance < 0) {
                // console.log(name + " doit payer " + (-balance) + " €.");
                } else {
                // console.log(name + " est à l'équilibre.");
                }
            }
            }

            // Calculer les remboursements nécessaires pour équilibrer le expenseBalancer
            function calculateRefunds() {
            // Créer un tableau refunds qui contient les remboursements à effectuer
            var refunds = [];
            // Calculer les balances
            var balances = calculateBalances();
            // Trier les participants par ordre croissant de balance
            var sortedParticipants = Object.keys(balances).sort(function (a, b) {
                return balances[a] - balances[b];
            });
            // Initialiser deux pointeurs : un sur le participant le plus débiteur et un sur le participant le plus créditeur
            var debtorIndex = 0;
            var creditorIndex = sortedParticipants.length - 1;
            // Tant qu'il reste des participants à équilibrer
            while (debtorIndex < creditorIndex) {
                // Récupérer le nom et la balance du débiteur
                var debtorName = sortedParticipants[debtorIndex];
                var debtorBalance = balances[debtorName];
                // Récupérer le nom et la balance du créditeur
                var creditorName = sortedParticipants[creditorIndex];
                var creditorBalance = balances[creditorName];
                // Calculer le montant du remboursement possible entre les deux participants
                var refundAmount = Math.min(-debtorBalance, creditorBalance);
                // Créer un objet remboursement avec le nom du débiteur, du créditeur et le montant
                var refund = {
                debtor: debtorName,
                creditor: creditorName,
                amount: refundAmount
                };
                // Ajouter l'objet remboursement au tableau refunds
                refunds.push(refund);
                // Afficher un message de confirmation
                // console.log(debtorName + " doit rembourser " + refundAmount + " € à " + creditorName + ".");
                // Mettre à jour les balances des deux participants
                balances[debtorName] += refundAmount;
                balances[creditorName] -= refundAmount;
                // Si le débiteur est à l'équilibre, passer au débiteur suivant
                if (balances[debtorName] === 0) {
                debtorIndex++;
                }
                // Si le créditeur est à l'équilibre, passer au créditeur précédent
                if (balances[creditorName] === 0) {
                creditorIndex--;
                }
            }
            // Retourner le tableau refunds
            return refunds;
            }

            // Afficher les remboursements nécessaires pour équilibrer le expenseBalancer
            function showRefunds() {
            // Calculer les remboursements
            var refunds = calculateRefunds();
    
            // Si il n'y a pas de remboursements à effectuer, afficher un message
            if (refunds.length === 0) {
                console.log("Le expenseBalancer est déjà équilibré.");
            }
            else{
                return refunds
            }
            }

            // Exemple d'utilisation

            // Ajouter des participants
            this.guestsAmount.forEach(user => {
                addParticipant(user.surname);
                addExpense(user.surname, user.Amount);
            });
            // addParticipant("Mae");
            // addParticipant("Bastien");
            // addParticipant("Max");
            // addParticipant("ju");
            // addParticipant("weird");
            // addParticipant("lolo");
            // addParticipant("moi");

            // Ajouter des dépenses
            // addExpense("Mae", 0, "Essence");
            // addExpense("Bastien", 0, "Courses");
            // addExpense("Max", 30, "Pizza");
            // addExpense("ju", 0, "Pizza");
            // addExpense("weird", 0, "Pizza");
            // addExpense("lolo", 0, "Pizza");
            // addExpense("moi", 13, "Pizza");

            // Afficher les balances
            showBalances();

            // Afficher les remboursements
            this.arr = showRefunds();

        }
    }
}
</script>
<style>
.returnBTN{
    position:absolute;
    top: 45px;
    left:25px;
    z-index: 5;
}
.eventviewheader .cloud1{
  width: 35%;
  position: fixed;
  left:75%;
  top:-5%;
  z-index: 1;
}
.eventviewheader .cloud2{
  width: 50%;
  position: fixed;
  left: -15%;
  top: -6%;
  z-index: 1;
}
.eventviewheader .cloud3{
  width: 20%;
  position: fixed;
  left: 5%;
  top: 25%;
  z-index: 1;
}
.eventviewheader .cloud4{
  width: 30%;
  position: fixed;
  left: 30%;
  top: 35%;
  z-index: 1;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.eventviewheader .cloud5{
  width: 25%;
  position: fixed;
  left: -5%;
  top: 53%;
  z-index: 1;
}
.eventviewheader .cloud6{
  width: 28%;
  position: fixed;
  left: 80%;
  top: 60%;
  z-index: 1;
}
.eventviewheader .cloud7{
  width: 35%;
  position: fixed;
  left: 15%;
  top: 75%;
  z-index: 1;
}
.eventviewheader .cloud8{
  width: 20%;
  position: fixed;
  left: 75%;
  top: 83%;
  z-index: 1;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.eventviewheader .cloud9{
  width: 25%;
  position: fixed;
  left: 85%;
  top: 42%;
  z-index: 1;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.eventviewheader .cloud10{
  width: 20%;
  position: fixed;
  left: 40%;
  top: 60%;
  z-index: 1;
}
.eventviewheader .beer1{
  width: 10%;
  position: fixed;
  left: 55%;
  top: 0%;
  z-index: 1;
  rotate: 15deg;
}
.eventviewheader .beer2{
  width: 10%;
  position: fixed;
  left: 24%;
  top: 19%;
  z-index: 1;
  rotate: -15deg;
}
.eventviewheader .beer3{
  width: 10%;
  position: fixed;
  left: 70%;
  top: 25%;
  z-index: 1;
  rotate: 10deg;
}
.eventviewheader .beer4{
  width: 10%;
  position: fixed;
  left: 70%;
  top: 54%;
  z-index: 1;
  rotate: -6deg;
}
.eventviewheader .beer5{
  width: 10%;
  position: fixed;
  left: 20%;
  top: 65%;
  z-index: 1;
  rotate: 10deg;
}
.eventviewheader .beer6{
  width: 10%;
  position: fixed;
  left: 10%;
  top: 40%;
  z-index: 1;
  rotate: 10deg;
}
.eventviewheader .beer7{
  width: 10%;
  position: fixed;
  left: 65%;
  top: 75%;
  z-index: 1;
  rotate: 18deg;
}
.eventviewbody{
    position: relative;
    z-index: 5;
}
.blockbcgwhite{
    background-color: white;
    border: 1px solid;
    border-radius: 25px;
}
.detailAmount {
    margin-top: 20%;
}
.myAmount{
    display: flex;
    justify-content: center;
}
.myAmount h2{
    margin: 0;
}
.myAmount input{
    margin: 0;
    margin-left: 5px;
    height: 37px;
    width: 65px;
    background-color:transparent;
    border: none;
    font-size: 25px;
    font-family: AmaticSC;
    text-align: right;
}
.myAmount input[type=number]::-webkit-inner-spin-button{
    -webkit-appearance: none;
}
.solde{
    margin-top: 15px;
    margin-bottom: 75px;
}
</style>