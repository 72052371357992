<template>
    <MqResponsive :target="['xs','sm','md']"> <!-- Mobile -->
        <div v-if="this.invitations && this.invitations.length >0" class="invitationsComp">
            <h2>Invitations:</h2>
            <div v-for="(event, index) in invitations" :key="index">
                <h3 class="notTitle">{{ event.surname }} Organise une soirée et tu es invité!</h3>
                <h3>Nom: {{ event.name }}</h3>
                <h4>{{ event.description }}</h4>
                <div>
                    <button @click="this.Accept(event.id_Event)"> Accepter </button> |
                    <button @click="this.Deny(event.id_Event)"> Refuser </button>
                </div>
            </div>
        </div>
    </MqResponsive>

</template>

<script>
import axios from 'axios';
import { vModelCheckbox } from 'vue';
export default {
    data() {
    return {
      invitations: null,
      timer: null,
    };
  },
  created() {
    this.GetInvitation();
  },
  mounted: function () {
    this.timer = setInterval(() => {
        this.GetInvitation()
    }, 5000)
    },
    beforeUnmount(){
        clearInterval(this.timer)
    },
  methods:{
    async GetInvitation(){
        // console.log("get invitations")
        try {
            const resp = await axios.post(this.$store.state.apiURI +'event/invitation/byuser', {
                    userid: this.$store.state.user.id,
                },{
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.state.token
                    }
                });
            this.invitations = resp.data[0]
            // console.log(resp.data[0])
        } catch (error) {
            console.error(error);
        }
    },
    async Accept(eventid){
        try {
            const resp = await axios.post(this.$store.state.apiURI +'event/invitation/accept', {
                    userid: this.$store.state.user.id,
                    eventid: eventid
                },{
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.state.token
                    }
                });
            this.GetInvitation();
            window.location.reload()
        } catch (error) {
            console.error(error);
        }
    },
    async Deny(eventid){
        try {
            const resp = await axios.post(this.$store.state.apiURI +'event/invitation/deny', {
                    userid: this.$store.state.user.id,
                    eventid: eventid
                },{
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.state.token
                    }
                });
            this.GetInvitation();
            window.location.reload()
        } catch (error) {
            console.error(error);
        }
    }
  }
}
</script>

<style>
.invitationsComp .notTitle{
    color: rgb(44, 62, 80);
}
</style>