import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import ResetView from '../views/ResetView.vue'
import RegisterView from '../views/RegisterView.vue'
import ProfileView from '../views/ProfileView.vue'
import CreateView from '../views/CreateView.vue'
import EventView from '../views/EventView.vue'
import EventDetailView from '../views/EventDetailView.vue'
import CreditsView from '../views/CreditsView.vue'
import SettingsView from '../views/SettingsView.vue'
import MentionsLegalesView from '../views/MentionsLegalesView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },{
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/resetmdp:token',
    name: 'resetmdp',
    component: ResetView
  },
  {
    path: '/register:token',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/create',
    name: 'create',
    component: CreateView
  },
  {
    path: '/event',
    name: 'event',
    component: EventView
  },
  {
    path: '/eventdetail',
    name: 'eventdetail',
    component: EventDetailView
  },
  {
    path: '/credits',
    name: 'credits',
    component: CreditsView
  },
  {
    path: '/mentionslegales',
    name: 'mentionslegales',
    component: MentionsLegalesView
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
