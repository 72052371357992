<template>
  <body> 
    <MqResponsive :target="['xs','sm','md']"> 
      <span class="alert" v-if="this.$store.state.version != this.$store.state.appversion">
        <img src="./assets/icons/alert.png" class="alertico"/>
        <span>
          <h1 class="alerttxt">Nouvelle version disponible !</h1>
          <h3 class="alerttxt">Ferme et Ré-ouvre ton application pour mettre à jour</h3>
        </span>
      </span>
      <router-view/>
      <span v-if="this.$store.state.token"> <!-- navbar if connected -->
        <nav class="navMobileimg" v-if="this.image"> 
          <router-link to="/create" class="create"><img src="./assets/icons/create.png"/></router-link>
          <router-link to="/"><img src="./assets/icons/home.png" /></router-link>
          <router-link to="/profile"><img src="./assets/icons/profile.png" /></router-link>
            <!-- |  -->
            <!-- <button @click="disconnect" >Disconnect</button> -->
        </nav>
        <nav class="navMobile" v-if="!this.image"> 
          <router-link to="/create" class="create">Create</router-link>
          <router-link to="/">Home</router-link>
          <router-link to="/profile">Profile</router-link>
            <!-- |  -->
            <!-- <button @click="disconnect" >Disconnect</button> -->
        </nav>
      </span>
    </MqResponsive>

    <MqResponsive :target="['lg','xl','xxl']" class="HomeBigScreen"> 
      <router-view/>
      
    </MqResponsive>
  </body>
</template>
<script>
import './assets/sass/style.scss';
import axios from 'axios';
var cookie = document.cookie;
export default {
  data(){
    return{
      image : true,
    }
  },
  created() {
    this.CheckVersion();
  },
  mounted: function () {
    this.timer = setInterval(() => {
      this.CheckVersion()
    }, 60000);
    this.CheckVersion();
  },
  beforeUnmount(){
    clearInterval(this.timer)
  },
  methods: {
    disconnect() {
      this.$store.commit('setToken', null);
      this.$store.commit('setUser', null);
      this.$router.push({name: 'home'});
    },
    async authUsingToken(token){
      try {
        const response = await axios.post(this.$store.state.apiURI +'token/jwt', {},{
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        console.log(response.status)
        switch (response.status) {
          case 201:
            //token expired
              this.disconnect() 
            break;
        
          default:
            if(response.data != ""){
              this.$store.commit('setToken', token);
              this.$store.commit('setUser', response.data.authData.user);
              this.$store.commit('setVersion', response.data.authData.version);
              this.$router.push({name: 'home'});
            }
            break;
        }
      } catch (err) {
        console.log(err);
      }
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    async CheckVersion(){
    try {
      const uri = this.$store.state.apiURI +'token/appversion';
      const response = await axios.get(uri);
      console.log(response.data.version)
      this.$store.state.version = response.data.version;
    } catch (error) {
      console.log(err);
    }
  }
  },
  async created(){
    var token = this.getCookie("token");
    console.log(token)
    if (token == "" || token == null || token == undefined) {

    }else{
      await this.authUsingToken(token)
    }
  },
  
}
</script>

<style>
#app {
  font-family: AmaticSC;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
background-color: #d0f6fd;
}

nav {
  position: fixed;
  bottom: 1%;
  width: 90%;
  padding-left: 0%;
  left: 5%;
  z-index: 100;
  border: 1px solid;
}

nav a {
  font-weight: bold;
  color: #000000;
  padding-right: 5%;
  padding-left: 5%;
  font-weight: none;
  text-decoration: none;

}

nav a.router-link-exact-active {
  color: #FF9627;
  text-decoration: underline;
  border-radius: 12px;
  filter: invert(71%) sepia(13%) saturate(6657%) hue-rotate(339deg) brightness(102%) contrast(102%);
}

.navMobile{
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 30px;
  /* left:0%; */
  background-color: white;
  border-radius: 12px;
}
.navMobileimg{
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 30px;
  border-radius: 12px;
  justify-content: space-around;
  display: flex;
  background-color: white;

}
.navMobileimg img{
  width: 30px;
}

button {
  background-color: transparent;
  font-weight: bold;
  border: none;
  text-decoration: underline;
  font-size: 25px;
  font-family: AmaticSC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 0%;
}
.alert{
  position: fixed;
  z-index: 10;
  top: 45%;
  width: 92%;
  display: flex;
  flex-direction: row-reverse;
  background-color: white;
  border: 1px solid;
  border-radius: 25px;
  justify-content: space-evenly;
}
.alert .alertico{
  margin: 5px;
  height: 12vh;
  margin-top: auto;
  margin-bottom: auto;
}
.alert .alerttxt{
  color: red;
  margin: auto;
  margin-left: 5px;
}
</style>
